import RNSError from './RNSError';

export default RNSError;
export { default as ErrorWrapper } from './ErrorWrapper';

export const NO_ADDR_RESOLUTION_SET = 'NO_ADDR_RESOLUTION_SET';
export const NO_ADDR_RESOLUTION = 'NO_ADDR_RESOLUTION';
export const NO_RESOLVER = 'NO_RESOLVER';
export const LIBRARY_NOT_COMPOSED = 'LIBRARY_NOT_COMPOSED';
export const NO_ADDRESSES_PROVIDED = 'NO_ADDRESSES_PROVIDED';
export const NO_CHAIN_ADDR_RESOLUTION = 'NO_CHAIN_ADDR_RESOLUTION';
export const NO_CHAIN_ADDR_RESOLUTION_SET = 'NO_CHAIN_ADDR_RESOLUTION_SET';
export const SEARCH_ONLY_SIMPLE_DOMAINS = 'SEARCH_ONLY_SIMPLE_DOMAINS';
export const SEARCH_DOMAINS_UNDER_AVAILABLE_TLDS = 'SEARCH_DOMAINS_UNDER_AVAILABLE_TLDS';
export const INVALID_DOMAIN = 'INVALID_DOMAIN';
export const INVALID_LABEL = 'INVALID_LABEL';
export const DOMAIN_NOT_EXISTS = 'DOMAIN_NOT_EXISTS';
export const NO_NAME_RESOLUTION = 'NO_NAME_RESOLUTION';
export const NO_REVERSE_RESOLUTION_SET = 'NO_REVERSE_RESOLUTION_SET';
export const NO_ACCOUNTS_TO_SIGN = 'NO_ACCOUNTS_TO_SIGN';
export const SUBDOMAIN_NOT_AVAILABLE = 'SUBDOMAIN_NOT_AVAILABLE';
export const INVALID_ADDRESS = 'INVALID_ADDRESS';
export const NO_SET_ADDR = 'NO_SET_ADDR';
export const INVALID_CHECKSUM_ADDRESS = 'INVALID_CHECKSUM_ADDRESS';
export const NO_TLD_OWNER = 'NO_TLD_OWNER';
export const NO_AVAILABLE_METHOD = 'NO_AVAILABLE_METHOD';
export const NO_REVERSE_REGISTRAR = 'NO_REVERSE_REGISTRAR';
export const NO_SET_NAME_METHOD = 'NO_SET_NAME_METHOD';
export const NO_SET_CHAIN_ADDR = 'NO_SET_CHAIN_ADDR';
export const NO_CONTENTHASH_INTERFACE = 'NO_CONTENTHASH_INTERFACE';
export const UNSUPPORTED_CONTENTHASH_PROTOCOL = 'UNSUPPORTED_CONTENTHASH_PROTOCOL';
export const NO_CONTENTHASH_SET = 'NO_CONTENTHASH_SET';
export const CHAIN_ID_NOT_SUPPORTED = 'CHAIN_ID_NOT_SUPPORTED';
